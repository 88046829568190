import axiosInst from '../index';
import { GatewayInformation } from './gatewayInformation';

export const requestGateways = () => {
  return axiosInst.get<GatewayInformation[]>('/gateway');
};

export const requestGateway = (id: string) => {
  return axiosInst.get<GatewayInformation>(`/gateway/${id}`);
};

export const createGateway = (gateway: GatewayInformation) => {
  return axiosInst.post('/gateway', gateway);
};

export const updateGateway = (gateway: GatewayInformation) => {
  return axiosInst.put(`/gateway/${gateway.id}`, gateway);
};

export const deleteGateway = (id: string) => {
  return axiosInst.delete(`/gateway/${id}`);
};
